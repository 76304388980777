.section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
}

.section_title {
    font-size: 1rem !important;
    margin: 0 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}