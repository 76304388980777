.input_wrap {
    margin-bottom: 16px;
}

.input_field{
    font-size: 0.875rem !important;
}

.right_element{
    right:20px !important;
}

.number_input{
    font-size: 14px !important;
}