.checkbox_wrap {
    margin-bottom: 16px;
}

.checkbox_field {
    display: flex;
    justify-content: space-between;
}

.wix_help_text {
    padding: 0.25rem 0;
    margin-left: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
}

.help_text {
    margin-left: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
}

.chakra_checkbox > span:last-child {
    font-size: 14px !important;
}