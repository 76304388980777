.customize_section {
  padding-bottom: 1rem;
}

.customize_section section {
  padding-top: 1rem;
}

.customize_section section:not(:first-child)::before {
  background-color: var(--p-divider);
  content: '';
  display: block;
  height: 0.0625rem;
  margin-bottom: 1rem;
}

.instruction_section,
.keyboard_section {
  padding-left: 1rem;
  padding-right: 1rem;
}

.customize_section h2 {
  font-size: 1rem !important;
  font-weight: 600;
  margin: 0 !important;
}

.customize_section p {
  font-size: 14px !important;
  max-width: 90%;
  margin-bottom: 0rem;
}

.icon {
  fill: var(--p-icon);
  width: 2.8125rem;
  height: 2.8125rem;
  margin-bottom: 0.2rem;
}

.editor_link {
  font-weight: 400;
  font-size: 1em;
  text-decoration: underline;
  color: var(--p-interactive);
  cursor: pointer;
}


.editor_link:hover {
  color: var(--p-interactive-hovered) !important;
  text-decoration: none;
}

.info_text {
  font-weight: 400;
  font-size: 1em;
}

.links_container {
  margin-top: 16px;
  padding-top: 0px !important;
}

.links_container::before {
  margin-bottom: 10px !important;
}

.links_wrapper::before {
  margin-bottom: 10px !important;
}

.sub_text_wrap {
  margin-top: 9px;
}

.sub_text {
  color: #6d7175;
}

.keyboard_keys {
  display: flex;
  margin-top: 12px;
}

.keyboard_keys kbd {
  color: #6d7175;
  margin-left: 4px;
}

.keyboard_keys>p {
  margin-left: 5px;
}

.key_width {
  width: 100px;
}