.dropdown_button {
    width: 100%;
}

.dropdown_button:hover {
    background: #f6f6f7;
}

.dropdown_wrap {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
    margin-left: 12px;
}

.block_button {
    margin-left: 3px;
}

.dropdown_title {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
}

.menulist_wrap {
    min-width: 298px !important;
}

.dropdown_options_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option_label {
    margin-left: 8px;
    font-size: 14px;
}

.option_icon {
    width: 16px;
    height: 16px;
}

.options_title {
    color: #6d7175;
    font-size: .75rem !important;
    font-weight: 600 !important;
}

.link_button {
    height: 36px;
    border-radius: 0.25rem;
}

.link_button_title {
    color: #2C696A;
}