html {
    /* Load system fonts */
    font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
        Roboto, Helvetica Neue, sans-serif;

    /* Make type rendering look crisper */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Deactivate auto-enlargement of small text in Safari */
    text-size-adjust: 100%;

    /* Enable kerning and optional ligatures */
    text-rendering: optimizeLegibility;
}


:root {
    --viewMode: 100%;
    --line-height: 20px;
    --heading-space: 1rem;
    --btw-component-space: 1rem;
    --label-component-space: 4px;
    --font-size: 14px;
    --header-font: 18px;
}

a:hover {
    color: black !important;
}

.Polaris-Heading {
    /* padding-bottom: 20px!important;
    margin-top:10px!important; */
    margin: 15px 15px 15px 0 !important;
}

.valueBlock .Polaris-Heading {
    margin: 0px !important;
    padding: 0px !important;
}

.component-spacing {
    margin-bottom: var(--heading-space) !important;
}

.sub-heading {
    padding: 4px 0;
}

.component {
    margin-bottom: var(--btw-component-space);
}

html {
    max-height: 100vh;
    overflow: hidden;
}

.Polaris-Heading {
    font-size: var(--header-font) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.paddingVal {
    padding: 0 0px 20px 22px !important;
}

body.mobile {
    --viewMode: 50%;
}

.main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: fixed;
    width: 100%;
    height: 56px;
    left: 0px;
    top: 0px;
    /* z-index: 999; */
    background: #ffffff;
    box-shadow: 0 2px 1px #0000000d, 0 0 1px #42474c73;

    justify-content: space-between;
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin: 2px 0px */
}

.main-layout {
    /* background: #e5e5e5; */
    margin-top: 3.5rem;
}

body {
    background-color: transparent !important;
}

.main-layout-inner {
    display: flex;
    min-height: calc(100vh - 60px);
}

.left-action-menu {
    /* width: 86px; */
    background: white;
    /* border-right: 1px solid #c4cdd5; */
}

.main-layout-left {
    width: 300px;
    flex: 0 0 302px;
}

.main-layout-right {
    height: 100%;
    flex: 0 0 300px;
}

.main-layout-center {
    width: 100%;
    overflow: hidden;
}

.center-section {
    height: calc(100vh - 56px);
    overflow: hidden;
    overflow-y: scroll;
    border: solid 10px #F3F6F8;
    width: var(--viewMode);
    margin: 0 auto;
}

.ce-preview {
    padding: 16px;
}

.ce-preview>.center-section {
    height: calc(100vh - 88px);
    border: none;
    box-shadow: 0 0 0 1px #3f3f440d, 0 1px 3px 0 #3f3f4426;
}

.right_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 56px);
    border-left: 1px solid #DDDFE1;
}

.right-section {
    background-color: #fff;
    /* display: flex; */
    flex: 1;
    flex-direction: column;
}

.bottom_remove_buttons {
    margin-top: auto;
}

.overflow_vertical_scroll {
    overflow-y: scroll;
}

.right-components {
    padding-bottom: 50px;
}

.checkbox_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.selected-font-box {
    border: 1px solid #d0d3d4;
    background: #f6f6f7;
    padding: 20px 17px;
}

.rangeslider-box {
    padding-top: 20px;
}

.rangeslider-box h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 30px 0;
}

.rangeslider-box .Polaris-Labelled__LabelWrapper {
    margin-bottom: 10px;
}

.selected-font-box h4 {
    font-weight: 700;
    font-size: 21px;
    margin: 0 0 10px 0;
    line-height: 20px;
}

.selected-font-box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 12px 0;
}

.left-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 55px);
    border-left: 1px solid #DDDFE1;
    border-right: 1px solid #DDDFE1;
}

.ue__icon_set .ue_infolabel {
    float: right;
}

/* Header component start*/

.header-back {
    padding: 16px;
    cursor: pointer;
    border-right: 1px solid #E8E8E8;
}

.header-left-section {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.header-right-section {
    padding-right: 20px;
    display: flex;
    align-items: center;
}

.header-select-dropdown {
    min-width: 347px;
    margin-top: -14px;
    margin-left: 24px;
}

.header-select-dropdown>div {
    height: 36px;
}

.header-select-dropdown button {
    min-width: 347px;
    margin-bottom: 10px;
}

.header-select-dropdown .Polaris-Button__Content {
    display: flex;
    justify-content: space-between;
}

.header-select-dropdown .Polaris-Button__Text {
    margin-left: -4px;
}

.header-select-dropdown .Polaris-Button {
    border: 0.0625rem solid var(--p-border-subdued)
}

.popover-actionlist-content .Polaris-ActionList__Title {
    margin-bottom: 0px;
}

.popover-actionlist-content .Polaris-ActionList__Text {
    line-height: 20px;
    font-size: 14px;
}

.popover-actionlist-content {
    width: 347px;
}

/* .header-select-dropdown>.Polaris-PositionedOverlay {
    left: 550px !important;
} */

.nav-view-button button {
    border: none;
    padding: 5px;
    margin: 10px;
    box-shadow: none;
}

.undo-redo-btn {
    display: flex;
    padding: 3%;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #DFE3E8;
}

.undo-redo-btn .nav-view-button button {
    margin: 0;
}

.nav-view-button button svg {
    fill: #5c5f62;
}

.nav-view-button button:hover,
.nav-view-button button:hover path {
    background-color: rgb(219, 219, 219);
    fill: rgb(24, 24, 24);
}

/* li .Polaris-ActionList__Item:hover {
    background-color: rgb(219, 219, 219);
} */
/* Header component end*/

.ue_infolabel {
    cursor: pointer;
}

/* left bar start */
.sidebar-menu-box a,
.sidebar-menu-box .accordion-title-span,
.sidebar-menu-box .block-title-span {
    display: flex !important;
    border-radius: 4px;
    align-items: center;
    /* color: #5c5f62; */
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 5px;
    padding-left: 10px;
    cursor: default;
}

.active-sections a,
.active-sections .accordion-title-span,
.active-sections .block-title-span {
    cursor: pointer;
}

.active-sections .accordion-title-span {
    padding-top: 8px;
    display: block !important;
}

.ce-leftbar .active-sections .section-container {
    margin-left: 1px;
}

.ce-leftbar .active-sections .section-list {
    padding-left: 16px;
}

.block-title-span {
    padding-left: 5px !important;
}

.block-title {
    display: contents;
    margin-top: -4px;
}

.block-title>div {
    margin-top: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wix-editor .block-title>div {
    margin-top: 0px;
}

.active-sections .accordion-title-span:hover {
    color: black;
}

.sidebar-menu-box .sub-menu a,
.sidebar-menu-box .sub-menu span {
    font-weight: 400;
}

.sidebar-menu-box .submenu-outer span+a {
    padding-left: 9px;
}

.sidebar-menu-box a span {
    margin-right: 10px;
}

.sidebar-menu-box ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-menu-box ul ul {
    cursor: pointer;
}

.accordion-dropdown-icon {
    display: flex;
    align-items: center;
    width: 20px;
    height: 36px;
    border-radius: 4px;
    fill: var(--p-icon);
    cursor: pointer;
    margin-left: 1px;
    margin-top: 1px;
    margin-right: 4px;
    justify-content: center;
}

.accordion-dropdown-icon-close {
    transform: rotate(270deg);
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.accordion-dropdown-icon-open {
    rotate: 0deg;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.accordion-dropdown-icon:hover {
    background-color: #212b3614;
}

.accordion-content {
    height: 2.25rem;
    margin-left: 0.5rem;
    border-radius: 0.25rem;
}


.accordion-content>a,
.accordion-content>span {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.accordion-content:hover {
    background-color: #f6f6f7;
}

.accordion-content:hover>span>span {
    color: #1a1c1d;
}

.accordion-content:hover img {
    filter: brightness(0.5);
}

.sub_list_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5C5F62;
    margin-bottom: 4px;
}

.sub_list_data:hover {
    color: #5c5f62;
}

li.sub_list_data:hover .visible {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
}

.accordion-title-outer:hover .visible {
    cursor: pointer;
    display: flex;
    align-items: center;
}

li.sub_list_data:hover .close-eye-icon {
    padding-right: 4px;
}

.accordion-title-outer:hover .close-eye-icon {
    padding-right: 4px;
}

.icon-bundle {
    display: flex;
    padding: 0px;
    height: 100%;
}

.icon-bundle .eye-icon {
    width: 16px;
    margin: 0px 2px;
    height: 16px;
}

.icon-bundle .grab-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.75rem;
    width: 20px;
}

.icon-bundle .grab-icon-wrapper>img {
    width: 13px;
    margin: 0px 5px;
    height: 13px;
}


.icon-bundle .delete-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.75rem;
    width: 20px;
}

.icon-bundle .delete-icon-wrapper>img {
    width: 14px;
    margin: 0px 4px;
    height: 14px;
}

.close-eye-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 31px;
    padding-left: 4px;
    border-radius: 4px;
}

.close-eye-icon:hover {
    background-color: #212b3614;
}

.close-eye-icon:hover svg {
    fill: black;
}

.visible {
    display: none;
    height: 100%;
    padding: 0 4px;
    border-radius: 4px;
}

.visible:hover {
    background-color: #212b3614;
}

.visible:hover svg {
    fill: black;
}

.hover-w-full {
    width: 100%;
}

.drag:hover .visible {
    display: flex;
    justify-content: space-between;
}

.sidebar-menu-box {
    padding: 4px 10px 10px 10px;
    background-color: #fff;
    flex: 1 1;
    overflow-y: scroll;
    flex-direction: column;
}

.offer-setting .sidebar-menu-box {
    height: 100% !important;
}

.sidebar-menu-box li {
    /* margin-bottom: 4px; */
}

.sidebar-menu-box .submenu-outer>a {
    position: relative;
}

.sidebar-menu-box .submenu-outer>div>span {
    position: relative;
    width: 12px;
    cursor: pointer;
    height: 12px;
}

.accordion-title-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.25rem;
    border-radius: 0.25rem;
    cursor: default;
    /* margin-bottom: 4px; */
}

.accordion-title-container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.accordion-title-container>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-top: 7px;
}

.accordion-title-container>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    padding-top: 7px;
}

.accordion-title-view {
    padding-left: 0px
}

.accordion-title-outer:hover {
    /* background-color: #f6f6f7; */
}

.active-sections .accordion-title-outer {
    cursor: pointer;
    margin: 2px;
}


.active-sections .accordion-title-outer:hover {
    background-color: #f6f6f7;
}

.accordion-title-outer:hover>a {
    filter: brightness(0.5);
}

.accordion-title-outer>a {
    padding: 0px;
    padding-left: 4px;
}

.accordion-title-outer>.accordion-title-span {
    padding: 0px;
    padding-left: 4px;
}

.sidebar-menu-box .submenu-outer>div>span.open:before {
    transform: translateY(-50%);
    top: 50%;
}

.sidebar-menu-box .submenu-outer>div>span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0 6px;
    border-color: #5c5f62 transparent transparent transparent;
    left: 4px;
    top: 4px;
    transform: rotate(-90deg) translateY(-50%);
}

.hide-submenu .sub-menu {
    display: none;
}

/* Test leftsidebar */

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordion-item.collapsed {
    max-height: 0;
    display: none;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

ul.accordion-item.sub-menu {
    border: none !important;
}

a.accordion-title.open>img {
    margin-right: 8px;
    width: 16px;
}

a.accordion-title>img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
}

span.accordion-title.open>img {
    margin-right: 8px;
    width: 16px;
}

span.accordion-title>img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
    display: initial;
}

li.accordion-content>a>span>img,
li.accordion-content>span>span>img {
    margin-right: 8px;
    width: 16px;
}

.accordion-item {
    border: none !important;
}

.add_block {
    padding-left: 8px;
    cursor: pointer;
}

.add_block .Polaris-Button__Text {
    margin-left: 8px;
}

.add-block-item {
    display: flex;
    align-items: center;
    height: 2.25rem;
    padding-left: 4px;
    border-radius: 4px;
}

.add-block-item:hover {
    background-color: #f6f6f7;
}

.add_block div button {
    text-decoration: none !important;
}


a.separator-title,
span.separator-title {
    cursor: default;
}

.add-block-item button:hover {
    color: var(--p-interactive);
}

.add-block-item .Polaris-Button--plain:hover svg,
.Polaris-Button--plain:focus svg {
    fill: var(--p-interactive);
}

a.separator-title:hover,
span.separator-title:hover {
    color: inherit;
}

.separator-title>span {
    border-bottom: 1px solid black;
    padding-bottom: 6px;
    width: 100%;
}

.separator-title-text {
    color: #000000;
    font-weight: 600;
}

.prview-wrp {
    background: #fff;
    border-bottom: 2px solid #dfe3e8;
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
}

.prview-wrp>span {
    display: block;
    margin: 0;
    padding: 3px 0px 3px 21px;
    width: 100%;
}

.prview-wrp>span>div {
    justify-content: space-between;
    display: flex;
}

.prview-wrp span strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.prview-wrp span p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #5c5f62;
}

.prview-wrp a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5988ff;
    margin: 0;
    text-decoration: none;
    padding: 0;
    padding-right: 20px
}

.offer-data {
    padding-left: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 24px;
}

.offer-icon {
    width: 37px;
    height: 40px;
    margin-bottom: 9.2px;
}

.offer-icon img {
    width: 100%;
    object-fit: cover;
}

.offer-icon {
    width: 37px;
    height: 40px;
}

.offer-title h1,
.keyboard-title h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
}

.offer-title p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #a1a1a1;
}

.keyboard_shortcuts {
    padding-left: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
}

.keyboard-title {
    margin: 24px 0;
}

.keyboard-title {
    margin: 24px 0;
}

.keyboard_key .buttons {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}

.keyboard_key .buttons p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.keyboard_key .buttons img {
    margin-right: 9px;
}

/* left bar end */


#fr-logo {
    display: none;
}

.exit-hover {
    /* display: none; */
    position: relative;
    left: -35px;
    opacity: 0;
    transition: 0.1s ease-in-out;
}

.product_button {
    margin-top: 10px;
}

.header-back:hover::after {
    content: "Exit";
    padding-left: 10px;
    left: 0px;
}

.header-back:hover .exit-hover {
    left: 0px;
    padding-left: 10px;
    opacity: 1;
}

.Polaris-ProgressBar--colorPrimary {
    position: fixed;
    z-index: 9999;
    height: 3px !important;
}

.fr-toolbar .fr-command.fr-btn svg {
    margin: 2px 4px !important;
    width: 20px !important;
}

.fr-toolbar .fr-command.fr-btn svg.fr-svg {
    height: 20px;
}

.section-content strong {
    font-weight: 500;
}

.checkout-preview .widget-heading a {
    color: #0d6efd !important;
    /* text-decoration: underline!important; */
}

.section-content a {
    color: #0d6efd !important;
    /* text-decoration: underline!important; */
}

.product_button {
    display: flex;
    flex-direction: column;
}

.product_button .spinner_button {
    padding: 10px;
}

.fr-toolbar.fr-top {
    border-radius: none !important;
    -webkit-border-radius: 0px 0px 0 0 !important;
}

.right-section-title {
    display: none;
    align-items: center;
    padding-left: 15px;
    border-bottom: 1px solid #E8E8E8;
    height: 50px;
}

.right-section-title span {
    margin: 0;
    position: relative;
    top: 2px;
}

.right-section-title h6 {
    margin: 0 0 0 13px;
    font-size: 20px;
}

.flexColor {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1614px) {
    .main-layout-right {
        order: 1;
    }

    .main-layout-left {
        order: 2;
    }

    .main-layout-center {
        order: 3;
    }

    .right-section-title {
        display: flex;
    }

    .sidebar-hide {
        display: none !important;
    }
}

.back-arrow {
    cursor: pointer;
    margin-right: 8px;
}

.back-arrow>img {
    width: 16px;
    height: 16px;
}

.colorblock {
    max-width: 34px;
}

.colorSelectorBox {
    padding: 20px;
    border-radius: 50%;
    cursor: pointer;
    /* border: 1px solid #E8E8E8; */

}

.colorSelector {
    padding-bottom: 15px;
    /* position: relative; */

}

.flex p {
    margin: 0px;
}

.flex {
    display: flex;
    align-items: center;
}

.flex .valueBlock {
    margin-left: 20px;
}

.colorPickerDiv {
    background: white;
    border: 1px solid #E8E8E8;
    padding: 5px;
    border-radius: 5px;
}

.outerDiv {
    position: absolute;
    width: 250px;
}

.innerDiv {
    /* position: absolute; */
    width: 100%;
    /* bottom: 0%;
    top:100%; */
    margin-top: 20px;
    padding: 6px 0px;
}

.Polaris-Choice__Label {
    font-size: 14px !important;
}

.mb-3 {
    margin-bottom: 19px !important;
}

p.Polaris-DisplayText.Polaris-DisplayText--sizeSmall {
    font-size: 18px !important;
    font-weight: 600;
}

.Polaris-Label #PolarisSelect1Label {
    font-size: 14px !important;
}

.Polaris-Label {
    margin-top: 15px;
    margin: 0px;
}

/* select-image */

.content-divider {
    border-top: 1px solid #D9D9D9;
    margin: 16px 0px;
    height: 0px !important;
}

.select-image-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.select-image-title h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #202223;
}

.select-image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 17px;
    gap: 11px;
    background: #f6f6f7;
    border: 1px solid #d0d3d4;
    border-radius: 4px;
    cursor: pointer;
}

.image_component_holder {
    margin-bottom: 12px;
}

.select-image {
    width: 100%;
    text-align: center;
}

.select-image h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.drop-upload-image-box {
    background: #ffffff;
    border: 2px dashed #8c9196;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px 20px;
}

.images-container {
    overflow-y: scroll;
    height: calc(100vh - 189px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-gap: 8px;
    margin-right: -1rem;
    margin-left: -1rem;
    padding: 0 1rem 1rem 1rem;

}

.images-container .Polaris-Labelled--hidden,
.images-container .Polaris-DropZone,
.images-container .Polaris-DropZone__Container,
.images-container .drop-upload-image-box {
    height: 100%;
}

.images-container .drop-upload-image-box {
    border: none;
}

.image-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    border-top: 1px solid #E8E8E8;
    gap: 10px;
}

.drop-upload-details {
    display: flex;
    flex-direction: column;
}

.drop-upload-details p {
    padding: 6px 8px;
    font-weight: bold;
    color: #4D81B4;
    background: #E2EFFC;
    border-radius: 8px;
    margin-bottom: 0px;
}

.drop-upload-details img {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 17px;
}

.drop-upload-details p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6d7175;
}

.drop-upload-image-bar h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5c5f62;
}

.drop-upload-image-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 15px;
    cursor: pointer;
}

.selected.drop-upload-image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 17px;
    gap: 11px;
    background: #f6f6f7;
    border: 1px solid #d0d3d4;
    border-radius: 4px;
}

.selected.drop-upload-image-box img {
    height: 100px;
    width: 100px;
    border: 1px solid #D0D3D4;
    border-radius: 2px;
}

.selected-buttons {
    display: flex;
    gap: 2px;
}

.image-section-container {
    /* height: calc(100vh - 168px); */
    position: relative;
}

.image-selector-wrap {
    padding: 16px;
}

.image-error-msg {
    margin-bottom: 16px;
}

.image-fields-wrap {
    margin: 16px;
}

.product-image-list .product-img {
    height: 120px;
    width: 120px;
    border: 1px solid #d0d3d4;
    border-radius: 4px;
    cursor: pointer;
    background-image: url("/public/assets/transperent_background.png");
}

.product-image-list .product-img.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image-list .product-img.loading img {
    width: 64px;
    height: 64px;
}

.product-image-list .product-img:first-child {
    border: none;
}

.product-image-list .product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-image-list .selected-img {
    border: 2px solid #4C78C8;
    position: relative;
    margin: 4px 0px 0px 4px;
    height: 112px;
    width: 112px;
}

.product-image-list .selected-img .tick-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    right: -10px;
    bottom: -10px;
    background-color: white;
    border-radius: 20px;
}

.content_sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1;
    flex-direction: column;
    border-bottom: 0px !important;
}

.bottom_container {
    padding-top: 3px;
    padding-bottom: 3px;
    border-top: 1px solid #E8E8E8;
    cursor: pointer;
    background: white;
    width: 100%;
    /* position: fixed;
    z-index: 9999999;
    bottom: 0%; */
    max-width: 305px;
    /* min-height: 52px; */
}

.left-bottom_container {
    padding: 0px;
    border-top: 1px solid #E8E8E8;
    cursor: pointer;
    background: white;
    width: 100%;
    max-width: 305px;
    min-height: 52px;
}

.offerSetting_sub_sections {
    padding: 16px 30px;
    transition: .2s ease-in-out;
}

.remove_button {
    display: inline-flex;
    padding: 8px 12px 8px 16px;
    border-radius: 0.25rem;
    align-items: center;
    font-weight: 400 !important;
    color: var(--p-text-critical) !important
}

.remove_button svg {
    fill: var(--p-text-critical) !important
}

.remove_button>svg {
    margin-right: 8px;
    margin-left: 0px;
    width: 16px;
    height: 16px;
}

.remove_button>.Polaris-Icon {
    margin-right: 10px;
    margin-left: 0px;
    margin-top: 2px;
    width: 20px;
    height: 21px;
}

.removeP ul ul {
    padding-left: 22px;
    cursor: pointer;
}

.removeP ul {
    padding: 0 15px 0 0;
    border-bottom: 1px solid #E8E8E8;
}

.removeP {
    padding: 0 !important;
}


.removeP .submenu-outer>div>span:before {
    transform: rotate(87deg) translateY(-50%);
}

.fontStyle {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.accordion-offersetting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

.removeDiv {
    display: none;
}

.none {
    display: none;
}

.list {
    padding-left: 25px;
    /* padding-top: 5px; */
}

.unauth_text {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.Polaris-TextField__Prefix {
    /* width: 100%; */
}

.Polaris-Stack.Polaris-Stack--spacingTight {
    width: 100% !important;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: white;
    border-radius: 100px;
    position: relative;
    border: 1px solid #e4e4e4;
    /* transition: opacity 0.25s ease 0s; */
    /* transition-property: opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: 0s; */
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 13px;
    height: 13px;
    border-radius: 45px;
    transition: 0.7s;
    background: whitesmoke;
    /* box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29); */
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    /* width: 30px; */
}

.badge-spacing {
    margin-left: 10px;
    margin-right: 0px !important;
}

.header-switch-container {
    margin-left: 16px;
    margin-bottom: 23.5px
}

.rightbar-wrap {
    height: calc(100vh - 109px);
}

.selected-item-active,
.selected-item-active:hover {
    background-color: #f2f7fe !important;
}

.Polaris-PositionedOverlay .add-sub-block-options {
    left: 0px;
    width: 246px;
    /* /* border: 1px solid #e5e5e5; */
}

.add-sub-block-options .Polaris-OptionList__Title {
    margin-bottom: 0px;
    font-size: 14px;
}

.add-sub-block-options .Polaris-OptionList__Title {
    margin-bottom: 0px;
    font-size: 14px;
    margin-left: 7px !important;
}

.add-sub-block-options img {
    width: 16px;
    height: 16px
}

.add-sub-block-options button {
    font-size: 14px;
}

.text-editor .Polaris-Tag {
    display: flex;
    align-items: flex-start;
    background-color: #AEAEAE;
    border-radius: 0px;
    height: 20px;
}

.text-editor .Polaris-Tag__TagText {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
}

.text-editor .Polaris-Tag__Button:hover {
    background-color: #AEAEAE;
}

.text-editor .Polaris-Tag__Button svg {
    fill: #5C5F62;
}

.bold-text {
    font-weight: 600 !important;
    color: var(--p-text);
    padding-left: 1px !important;
}

.subSec {
    padding-left: 30px !important;
}

.tag-label {
    font-size: 14px;
}

.title-spacing {
    margin-left: 17px;
}

.section-list {
    padding-left: 18px;
}

.left-section>div {
    /* border-bottom: 1px solid #DDDFE1 !important; */
    /* border-bottom: none; */
}

 .left-section>div {
    border-bottom: 1px solid #DDDFE1 !important;
    /* border-bottom: none; */
}

.theme-block-section::-webkit-scrollbar-track {
    border-radius: 0px;
    border: 0px solid #DDDFE1;
}

.leftbar_page_heading {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 16px 12px 12px 12px;
}

.leftbar_heading_divider {
    border-top: 1px solid #D9D9D9;
    margin: 13px 0px;
    height: 0px !important;
    margin-left: -11px;
    width: 282px;
}

.leftbar-divider {
    margin: 16px 16px 3px 10px;
    background-color: #DDDFE1;
    height: 1px;
}

.leftbar_header {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 12px 5px 11px 18px;
}

.leftbar_footer {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 11px 5px 13px 16px;
}

.link-body .Polaris-SkeletonBodyText {
    width: 250px;
}

.skeleton-wrapper .Polaris-SkeletonPage__Header {
    display: none;
}

.skeleton-wrapper .Polaris-SkeletonPage__Page {
    margin: 0px;
    padding: 0px;
    margin-left: 21px
}

.skeleton-wrapper .Polaris-SkeletonDisplayText--sizeSmall {
    width: 25px;
    margin-right: 10px;
    height: 25px;
}

.skeleton-wrapper .Polaris-SkeletonBodyText {
    margin-top: 9px;
}

.skeleton-content-wrapper {
    display: flex;
    margin-bottom: 10px;
}

.skeleton-main-block {
    margin-top: 12px;
    margin-bottom: 12px;
}

.skeleton-sub-block {
    margin-left: 40px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.skeleton-details {
    width: 100%;
}

.exit-button-container:hover+div.title-text {
    opacity: 0 !important;
    transform: translateX(0.5rem)
}

.exit-button-container {
    display: contents
}

.title-text {
    margin-left: 52px;
    font-size: 14px;
    margin-top: -3px;
}

.badge-container {
    margin-top: -3px;
    margin-right: 1rem;
}

.badge-container .Polaris-Text--bodySm {
    font-size: .8125rem;
}

.add-section-button .Polaris-PositionedOverlay {
    left: 300px
}

.product_search input[type="text"] {
    margin-bottom: 0px;
    border: none;
    background-color: transparent;
}

.rightbar-content {
    padding: 16px;
}

.activator-container>.Polaris-Button {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-left: 8px;
    background: transparent;
}

.disabled-add-button {
    cursor: default;
}

.wix-disabeld-add-btn {
    cursor: default;
    display: flex;
    color: #8c9196;
    height: 36px;
    align-items: center;
    font-size: 14px;
    margin-left: 11px;
}

.wix-disabeld-add-btn>img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.disabled-add-button>.Polaris-Button {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-left: 8px;
    cursor: default !important;
    pointer-events: none;
    background: transparent;
}

.disabled-add-button>.Polaris-Button:hover {
    background: none;
}


.disabled-add-button>.Polaris-Button>span {
    justify-content: flex-start;
    color: #8c9196;
    padding-left: 8px;
    font-weight: 400;
}

.disabled-add-button>.Polaris-Button svg {
    fill: var(--p-icon-disabled);
}

.add-section-padding>.Polaris-Button {
    padding-left: 21px;
}


.activator-container>.Polaris-Button>span {
    justify-content: flex-start;
    color: #4C78C8;
}

.activator-container>.Polaris-Button svg {
    fill: var(--p-interactive);
}

.option-title {
    padding: 16px 16px 0px 16px;
    font-size: 0.75rem;
    font-weight: 600;
    color: #6d7175;
}

.popover-list-container .Polaris-Listbox {
    padding: 8px;
}

.popover-list-container .Polaris-Listbox-TextOption {
    padding: 0px;
    margin: 0px;
}

.popover-list-container .Polaris-Listbox-TextOption__Content {
    padding: 8px 8px;
    display: block;
    font-size: 14px;

}

.active-button-container>button {
    background-color: #f2f7fe
}

.list-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: -3px;
}

.divider-text {
    font-size: 14px;
    text-align: center;
}

.section-popover-wrapper {
    left: 278px !important;
    margin-top: -43px
}

.section-wrapper-visibility {
    visibility: hidden;
}

.links-container {
    background: white;
    padding: 1px 19px;
    font-size: 14px;
    font-weight: 600;
}

.link-divider {
    border-top: 1px solid #D9D9D9;
    margin: 0px;
    height: 0px !important;
}

.links-container>* {
    margin: 0px 0px 15px 0px;
}

.link-title {
    color: #A1A1A1;
}

.link-content {
    color: var(--p-interactive);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
    font-size: 1em;
}

.link-content:hover {
    color: var(--p-interactive-hovered);
    text-decoration: none;
}

.add-section-wrap .Polaris-Button__Content {
    padding-left: 4px;
}

.leftbar-banner-wrap .Polaris-Box>.Polaris-Icon {
    display: none;
}

.leftbar-banner-wrap .Polaris-Box {
    padding: 0px
}

.leftbar-banner-wrap {
    padding: 10px;
    border: none !important;
    border-top: 0px solid #DDDFE1 !important;
    border-bottom: 0px solid #DDDFE1 !important;
}

.leftbar-banner-wrap .Polaris-List {
    list-style: none;
    padding-left: 2px;
    font-size: 14px;
}

.leftbar-banner-wrap>div {
    padding: 10px;
}

.leftbar-banner-wrap .Polaris-Banner__Button {
    font-size: 14px;
    font-weight: 500;
    border-color: rgba(186, 191, 195, 1) !important;

}

.warning-banner-link {
    padding-top: 8px;
    text-decoration: none;
    color: var(--p-interactive);
    margin-bottom: 5px;
}

.warning-banner-link:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.leftbar-banner-wrap .Polaris-Banner__Actions {
    padding-top: 0px !important;
}

.custome-header-link {
    margin-top: 10px;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    color: var(--p-interactive);
    cursor: pointer;
}

.custome-header-link:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.section-location-banner-wrap .Polaris-Banner__Ribbon {
    display: none;
}

.section-location-banner-wrap .Polaris-Box>.Polaris-Icon {
    display: none;
}

.section-location-banner-wrap .Polaris-Box {
    padding: 0px;
}

.section-location-banner-wrap .Polaris-Bleed>.Polaris-Box>p {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 15px;
}

.section-location-banner-wrap .Polaris-Bleed {
    display: grid;
    align-items: center;
}

.section-location-banner-wrap .Polaris-Banner--withinPage {
    padding: 0px;
    margin-top: 4px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.section-location-banner-wrap>span {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
}

.section-location-banner-wrap .Polaris-Banner__Content>p {
    margin-bottom: 0px;
    padding: 6px 10px;
    text-align: center;
    font-size: 15px;
}

.image-block-link {
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.leftbar-add-section .Polaris-Button {
    padding: 16px 19px;
}

.leftbar-add-section>div {
    height: auto !important;
    border-top: 0px solid #DDDFE1;
}

.leftbar-add-section .Polaris-Button {
    padding: 16px 19px;
}

.add-section-drop-btn>.Polaris-Button>span {
    justify-content: center;
    color: #1879B9;
    font-weight: 400;
    font-size: 14px;
}

.add-section-drop-btn>.Polaris-Button svg {
    fill: #1879B9;
}

.add-section-drop-btn {
    border: 1px dashed #1879B9;
    border-radius: 4px;
    margin-bottom: 4px;
}

.wix-editor .add-section-drop-btn{
    cursor: pointer;
    border: 1px dashed #1879B9;
    border-radius: 4px;
    margin-bottom: 4px;
    height: 36px;
    display: flex;
    justify-content: center;
    background: #f2f7fe;
    align-items: center;
    color: #1879b9;
    font-size: 14px;
}

.wix-editor .add-section-drop-btn:hover {
    background: #f6f6f7;
}

.add-section-drop-btn>.Polaris-Button {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-left: 8px;
}

.add-section-drop-btn .Polaris-Icon {
    width: 15px;
    height: 15px;
}


body[data-lock-scrolling] {
    overflow-y: auto;
}

body #crisp-chatbox>div>a {
    bottom: 54px !important;
}

.settings-title {
    display: flex;
    border-radius: 4px;
    align-items: center;
    color: #000000;
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    padding: 5px 5px;
    padding-left: 10px;
    cursor: pointer;
    margin-left: 11px;
}

.settings-header {
    font-size: 16px;
    font-weight: 600;
}

.color-labels {
    font-size: 14px;
    font-weight: 600;
}

.offer-settings .Polaris-Labelled__LabelWrapper {
    margin: 25px 0px 20px 0px;
}

.offer-settings .Polaris-Label__Text {
    font-size: 15px;
}

.color-help-text {
    font-size: 14px;
    font-weight: 400;
    color: #868686;
}

.typography-card {
    border: 1px solid lightgray;
    padding: 20px 17px !important;
    border-radius: 10px;
    background-color: #F6F6F7 !important;
}

.font_selector_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 56px);
}

.font_selector_top_section {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
}

.font_selector_controls {
    width: 100%;
    padding: 6px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #E8E8E8;
}

.font-list-content {
    font-size: 14px;
    font-weight: 500;
    color: #5C5F62;
    padding: 20.5px 15px !important;
    border-bottom: 1px solid #E8E8E8;
}

.font-list-header-content {
    font-size: 10px;
    font-weight: 500;
    padding: 11.5px 15px !important;
    border-bottom: 1px solid #E8E8E8;
}

.font-list-header-content:hover {
    background-color: transparent !important;
}

.font-list-content>div {
    width: 100%;
    display: flex;
}

.font-list-description {
    margin-top: 3px;
    color: #969696;
    font-weight: 400;
    margin-bottom: 0px;
}

.selected-font {
    font-size: 14px;
    font-weight: 500;
}

.selected-font-container {
    color: #5C5F62;
    padding: 12.5px 10px;
    border-top: 1px solid #E8E8E8;
}

.font-style-selector .Polaris-Button:hover {
    background: transparent;
    outline: none
}

.font-style-selector .Polaris-Button {
    padding: 0px;
    margin: 0px;
    color: #969696;
    border: none;
    min-height: 0px;
    margin-top: 0px;
    background: transparent;
    box-shadow: none;
}

.font-style-selector .Polaris-Button__Icon {
    display: none;
}

.font-style-selector .Polaris-Button__Text {
    display: flex;
    font-size: 10px;
    font-weight: 400;
}

.font-style-selector .Polaris-Button .Polaris-Icon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}

.font-style-selector>div {
    line-height: 0px;
}

.font-style-list-content {
    width: 239px;
}

#root {
    height: 100%;
}

.spinner-icon {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn,
.fr-modal .fr-command.fr-btn {
    padding: 0px 6px !important;
}

.fr-toolbar .fr-btn-grp {
    margin: 0px 14px !important;
}

@media (min-width: 768px) {
    .fr-popup .fr-layer {
        width: 211px !important;
    }
}

.fr-toolbar.fr-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.fr-second-toolbar {
    display: none;
}

.fr-box.fr-basic .fr-wrapper {
    border-bottom-color: #CCCCCC !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.actionbar-content {
    padding-top: 32px;
    justify-content: center;
    display: grid;
}

.actionbar-btn {
    margin: 4px 10px;
    width: 36px;
    height: 36px;
    display: flex;
    border-radius: var(--p-border-radius-1)
}

.actionbar-btn:hover {
    background-color: #e4e5e7;
    color: var(--p-icon-hovered);
    cursor: pointer;
}

.actionbar-btn:hover svg {
    fill: var(--p-text);
}

.active-actionbar-btn {
    background-color: #f2f7fe;
    color: var(--p-interactive);
}

.active-actionbar-btn {
    background-color: #f2f7fe;
    color: var(--p-interactive);
}

.active-actionbar-btn svg {
    fill: var(--p-interactive) !important;
}

.active-actionbar-btn:hover {
    background-color: #edf4fe;
    color: var(--p-interactive-hovered);
}

.active-actionbar-btn:hover svg {
    fill: var(--p-interactive-hovered) !important;
}

.add-section-activator:hover {
    background-color: #e4e5e7;
    color: var(--p-icon-hovered);
}

.add-section-activator:hover svg {
    fill: var(--p-text);
}

.add-section-activator:hover>.Polaris-Button>span {
    color: var(--p-icon-hovered);
}

.add-section-activator .Polaris-Icon {
    width: 24px;
    height: 24px;
    margin-right: 2px;
}

.add-section-activator .Polaris-Button__Content {
    font-weight: 400;
    color: var(--p-interactive);
}

.add-section-activator .Polaris-Button {
    padding: 10px 14px
}

.bottom_container:hover {
    background-color: #e4e5e7;
    color: var(--p-icon-hovered);
}

.bottom_container:hover .remove_button {
    color: var(--p-icon-hovered) !important;
}

.bottom_container:hover svg {
    fill: var(--p-text) !important;
}

.leftbar-banner-wrap .Polaris-Box>.Polaris-Icon {
    display: none;
}

.leftbar-banner-wrap .Polaris-Box {
    padding: 0px
}

.bottom_container:hover path {
    fill: #202223 !important;
}

.layout-progress-bar {
    z-index: 999999;
    position: absolute;
    height: 50%;
}

.layout-progress-bar>div {
    height: 50%;
}

.help-modal-iframe {
    width: 100%;
    height: calc(100vh - 280px);
}

.section-details-wrap {
    margin: 16px;
}

.editor-wrap {
    word-break: break-word
}

.fr-element a {
    color: #0d6efd !important;
    /* text-decoration: underline!important; */
}

.fr-toolbar .fr-command.fr-btn img {
    width: 12px !important;
    height: 12px !important;
}

.custome-margin-bottom {
    margin-bottom: 16px;
}

.image-select-button {
    background: #ffffff;
    width: 100%;
    border: 1px solid #babfc3 !important;
}

.chakra-btn {
    background: #ffffff;
}

.leftbar-banner-wrap .Polaris-Banner__Ribbon {
    display: none;
}

.fr-dropdown-list li a[data-param1="10px"] {
    font-size: 10px;
}

.fr-dropdown-list li a[data-param1="12px"] {
    font-size: 12px;
}

.fr-dropdown-list li a[data-param1="14px"] {
    font-size: 14px;
}

.fr-dropdown-list li a[data-param1="16px"] {
    font-size: 16px;
}

.fr-dropdown-list li a[data-param1="18px"] {
    font-size: 18px;
}

.fr-dropdown-list li a[data-param1="24px"] {
    font-size: 24px;
}

.product-selector-banner .Polaris-Box>.Polaris-Icon {
    display: none;
}

.product-selector-banner .Polaris-Box {
    padding: 0px;
}


.product-selector-banner {
    margin-top: 6px;
    font-weight: 600;
}

.Polaris-Frame-Toast--error>.Polaris-Frame-Toast__LeadingIcon {
    justify-content: center;
    align-items: center;
    display: flex;
}
