.custom_tooltip {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #202223;
    margin-top: 16px;
}

.label_wrap {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}