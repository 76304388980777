.Polaris-TextField__Prefix{
    margin: 0px!important;
    padding: 5px!important;
}
.Polaris-TextField__Input{
    padding: 5px!important;
    margin-bottom: 0px !important;
}

.Polaris-Stack.Polaris-Stack--spacingTight{
    width: 266px;
}
.Polaris-PositionedOverlay{
    z-index: 99999999!important;
}

.Polaris-TextField__VerticalContent{
    overflow: auto;
}