.preview_button {
    text-align: center;
    margin-bottom: 16px;
}

.preview_button span {
    font-weight: 400;
}

.chakra_btn {
    background: #ffffff;
    font-weight: 400 !important;
    font-size: 14px !important;
}