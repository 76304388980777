.button_container button {
    border: none;
    padding: 5px;
    margin: 10px;
    box-shadow: none;
}

.button_container button:hover {
    background-color: rgb(219, 219, 219);
}

.button_container button:hover path {
    fill: rgb(24, 24, 24);
}
