.preview_container {
    padding: 10px 20px;
    width: 300px;
    background: #FFFFFF;
}

.first_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.sub_heading {
    margin: 0;
    padding: 0;
    color: #5C5F62;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
}

.change_link {
    text-decoration: none;
    color: #5988FF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.change_link:hover {
    text-decoration: none;
    color: #1f5199 !important;
}