.custom_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom_heading button {
    border: none;
}

.custom_heading h2 {
    margin: 0px !important;
    line-height: 24px !important;
    font-size: 16px !important;
    font-weight: 600;
}