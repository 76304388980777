.selector_wrap {
    margin-bottom: 16px;
}

.button_wrap {
    width: 100%;
}

.button_container {
    margin: 16px 0px;
}

.button_groups {
    margin: 16px;
    justify-content: center;
}

.main_button {
    margin-left: 8px;
}

.delete_btn {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected_image {
    width: 38px !important;
    height: 38px !important;
}

.image_wrap {
    border: 1px solid #e1e3e5;
    border-radius: 0.25rem;
}

.input_grop_wrap {
    border: 0.0625rem solid #e1e3e5;
    border-radius: 5px;
}

.load_more_btn {
    width: 100%;
}