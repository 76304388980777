.exit_button {
  display: flex;
  padding: 17.5px 18px;
  cursor: pointer;
  position: fixed;
  border-right: 1px solid #c4cdd5;
}

.exit_button:hover {
  padding: 17.5px 12px 17.5px 18px;
}

.exit_span {
  position: relative;
  left: -35px;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.exit_button:hover::after {
  content: 'Exit';
  padding-left: 0px;
  padding-right: -2px;
  left: 0px;
  font-size: .875rem;
  color: #1a1c1d;
  font-weight: 500;
  margin-top: -2px;
  margin-right: 0px;
}

.exit_button:hover .exit_button_icon svg {
  fill: #202223;
}

.exit_button:hover .exit_span {
  left: 0px;
  padding-left: 10px;
  opacity: 1;
}

.exit_button_icon {

}