

.settings-content {
    padding-top: 17px;
}

.Colors-content {
    padding-top: 2px;
    /* font-size: 16px;
    font-weight: 600; */
}