.banner_wrap {
    margin-top: -5px;
}

.banner_box{
    border: 1px solid #babfc3;
    font-size: 15px;
    margin-bottom: 0;
    padding: 6px 10px;
    text-align: center;
    border-radius: 4px;
    margin-top: 4px;
    background: #f6f6f7;
}

.location_wrap {
    font-size: 12px;
    margin-top: -5px;
}

.location_banner_span {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
}