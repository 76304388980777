.close_button {
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.close_button:hover {
    background-color: rgb(219, 219, 219);
}

.close_button:hover svg {
    fill: var(--p-icon-hovered) !important;
}

.close_button_wrap {
    width: 43px;
    height: 36px;
}