.theme-block-section{
  position: absolute;
    border: 1px solid #e5e5e5;
    padding: 16px 20px;
    border-radius: 5px;
    z-index: 9999;
    background-color: white;
    width: 100%;
    max-height: 221px;
    overflow-y: scroll;
    max-width: 80%;
    transition: 1s ease-in-out;
    box-shadow: 0 0px 2px 0px #515050;
}

.theme-block-section div {
    font-size: 12px;
    font-family: var(--p-font-family-sans);
    FONT-WEIGHT: 600;
    color: var(--p-text-subdued);
}