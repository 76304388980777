.image_container {
    padding-inline-start: 0px !important;
}

.group_container {
    height: 120px;
    width: 120px !important;
    background: #fff;
}

.upload_button{
    background: #e2effc;
    border-radius: 8px;
    margin-bottom: 0;
    padding: 6px 8px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: #6d7175;
    font-weight: 400;
    text-align: center;
}