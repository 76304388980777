.card{
    border:1px solid lightgray;
    padding: 20px;
    border-radius: 10px;
}

.flex-space{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pointer{
    cursor: pointer;
}

.product_selector_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 56px);
}

.product_top_section {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.product_bottom_section {
    margin-top: auto;
}

.specific_product_list {
    display: flex;
}


.product-image {
    border-radius: 5px;
    border:1px solid lightgray;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
    overflow: hidden;
    align-items: center;
    max-width: 60px;
    display: flex;
}

.product-image img{
    width: 100%;
}

.product_image img, .selected_product_image img {
    width: 100%;
    object-fit: cover;
    margin: 0;
    height: 100%;
}

.specific_product_list ul {
    list-style-type: none;
    width: 100%;
    padding: 0px;
}

.product_item_container {
    padding-left: 8px;
    padding-right: 8px;
}

.specific_product_list ul li {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 0.25rem;
}

.specific_product_list ul li:hover{
    background-color: #f6f6f7;
}

.specific_product_list ul .product_item_container {
    border-left: 3px solid transparent;
}

.specific_product_list ul .selected_item {
    border-left: 3px solid rgba(44, 110, 203, 1);
}

.specific_product_list ul li.selected, .specific_product_list ul li.selected:hover {
    background-color: #f2f7fe;
} 

.load_more_button_container {
    padding: 0.75rem;
    width: 100%;
}

.flex-list{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.product-title {
    font-size: 0.875rem;
    margin-left: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_subtitle {
    color: #6d7175;
    font-size: 10px;
}

.selected-product-title{
 font-size: 13px;
}

.current-product-selector{
    background-color: #fff;
    bottom: 41px;
    padding-bottom: 20px;
    position: absolute;
    padding-left: 10px;
    padding-top: 10px;
}

.selected_product_container {
    display: flex;
    width: 100%;
    height: 4rem;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    border-top: 0.0625rem solid var(--p-divider);
    border-bottom: 0.0625rem solid var(--p-divider);
}

.product_selector_controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px; 
    border-top: 1px solid #E8E8E8;
}

.product-bottom-continer{
    cursor: pointer;
    background: white;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    bottom: 0%;
    max-width: 300px;
}

.position-re{
    position: relative;
}
.filter_block{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.filter_block .ul_body1 {
    width: 100%;
}

.filter_block ul {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.ul_body1{
    height: 50vh;
    overflow-y: scroll;
}



